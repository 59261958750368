:root {
	--color-primary: #EF0D33;
	--color-primary-light: #FF264A;
	--color-primary-dark: #D91500;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #cccccc;
	--color-grey-dark-1: #111517;
	--color-grey-dark-2: #888;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
}
h1,h2,h3,h4,h5,h6 {
	font-family: 'Pathway Gothic One', sans-serif;
}

.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}
.input-container label {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.input-container input,
.input-container textarea,
.input-container select {
	padding: 1rem;
	border: none;
	background-color: rgba(255, 255, 255, .8);
	outline: none;
	resize: none;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
	width: 100%;
}
.input-container button {
	border: 1px solid var(--color-primary);
	color: var(--color-primary);
	padding: 1rem;
	text-transform: uppercase;
	font-size: 1.4rem;
	cursor: pointer;
	background-color: transparent;
	outline: none;
	transition: all .5s;
}
.input-container button:hover {
	background-color: var(--color-primary);
	color: #fff;
}
.login {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login form {
	width: 100%;
	max-width: 40rem;
	background-color: #fff;
	padding: 2.5rem;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.login form h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	text-align: center;
}

/* CLIENT */
.client header {
	position: fixed;
	width: 100%;
	height: 6rem;
	display: flex;
	background-color: #fff;
	z-index: 9;
}
.header__container {
	width: 95%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
}
.header__container .logo {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	font-weight: 800;
	color: var(--color-primary);
	letter-spacing: 2px;
	margin-right: 2.5rem;
}
.header__container .logo img {
	height: 4rem;
}
.header__container ul {
	flex: 1;
	display: flex;
	height: 100%;
}
.header__container ul a {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: var(--color-grey-dark-1);
	font-size: 1.4rem;
	text-transform: uppercase;
	font-weight: 500;
	transition: all .5s;
}
.header__container ul a:hover,
.header__container ul a.active {
	background-color: var(--color-primary);
	color: #fff;
}
.header__container > a {
	padding: .5rem 2rem;
	background-color: var(--color-primary);
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	margin-left: 2.5rem;
	border-radius: 5rem;
	text-transform: uppercase;
}
.client footer {
	background-color: var(--color-grey-dark-1);
	display: flex;
}
.client .footer__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	padding: 4.5rem 0 0;
}
.client .footer__top {
	display: flex;
	padding-bottom: 4.5rem;
	border-bottom: 1px solid rgba(255,255,255,.3);
}
.client .footer__top ul {
	display: flex;
	flex: 0 0 20%;
	flex-direction: column;
	align-items: flex-start;
}
.client .footer__top ul a {
	color: var(--color-grey-light-4);
	margin: 1rem 0;
	text-decoration: none;
	font-size: 1.4rem;
	padding: .5rem 2rem;
}
.client .footer__top ul a:hover {
	text-decoration: underline;
}
.client .footer__bottom {
	padding: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.client .footer__bottom p,
.client .footer__bottom a {
	color: var(--color-grey-light-4);
	font-size: 1.4rem;
}
.home {
	min-height: 100vh;
}
.home__cover {
	height: 80vh;
	background-image: url('/images/a1.png');
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
}
.home__cover-container {
	width: 100%;
	max-width: 75rem;
	margin: 0 auto;
	background-image: linear-gradient(to right bottom, rgba(255,255,255,0) 45%, rgba(255,255,255,.3));
	padding: 2.5rem;
}
.home__cover-container h1 {
	font-size: 5.5rem;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: var(--color-primary-light);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
	font-weight: 900;
}
.home__cases {
	display: flex;
	padding: 9rem 0;
}
.home__cases-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}
.home__cases-container h2 {
	font-size: 4.5rem;
	text-align: center;
	margin-bottom: 2.5rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
}
.home__cases-container ul {
	display: flex;
}
.home__cases-container a {
	padding: 1.5rem 2.5rem;
	color: #fff;
	margin: 4.5rem auto auto;
	background-color: var(--color-primary);
	text-decoration: none;
	font-size: 1.4rem;
	text-transform: uppercase;
}
.home__separator {
	padding: 12.5rem 2rem;
	display: flex;
	background-image: url('/images/a6.png');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}
.home__separator-container {
	width: 100%;
	max-width: 100rem;
	margin: auto;
	display: flex;
	flex-direction: column;
}
.home__separator-container h2 {
	text-align: center;
	font-size: 4.5rem;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 3px;
}
.home__separator-container a {
	padding: 1.5rem 2.5rem;
	color: #fff;
	margin: 1rem auto;
	background-color: var(--color-primary);
	text-decoration: none;
	font-size: 1.4rem;
	text-transform: uppercase;
	box-shadow: var(--shadow-dark);
}
.home__about {
	padding: 9rem 0;
	display: flex;
}
.home__about-container {
	width: 100%;
	max-width: 80rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
}
.home__about-container .content {
	flex: 1;
	margin-right: 2rem;
	display: flex;
	flex-direction: column;
}
.home__about-container h3 {
	font-size: 2.5rem;
	color: var(--color-grey-dark-1);
	margin-bottom: 1rem;
}
.home__about-container p {
	font-size: 1.4rem;
	color: var(--color-grey-dark-3);
	text-align: justify;
	margin-top: 1rem;
}
.home__about-container picture {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.home__about-container picture img {
	width: 100%;
}
.home__about-container .content a {
	margin-top: 2rem;
	padding: 1.5rem;
	background-color: var(--color-primary);
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	margin-left: auto;
	text-transform: uppercase;
}
.home__contact {
	padding: 9rem 0;
	background-color: #fff;
	display: flex;
}
.home__contact-container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.home__contact-container h3 {
	font-size: 2.5rem;
	color: var(--color-grey-dark-1);
}
.home__contact-container p {
	font-size: 1.4rem;
	margin-top: 1rem;
}
.home__contact-container form {
	display: flex;
	margin-top: 4.5rem;
	align-items: flex-end;
}
.home__contact-container form label {
	font-size: 1.4rem;
	color: var(--color-primary);
	padding: 1rem 1rem 1rem 0;
}
.home__contact-container form input {
	flex: 1;
	padding: 1rem;
	font-size: 1.4rem;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--color-primary);
	outline: none;
	color: var(--color-grey-dark-1);
}
.home__contact-container form button {
	padding: 1rem 2rem;
	font-size: 1.4rem;
	border: 1px solid var(--color-primary);
	background-color: transparent;
	color: var(--color-primary);
	cursor: pointer;
	font-weight: 500;
	transition: all .5s;
}
.home__contact-container form button:hover {
	background-color: var(--color-primary);
	color: #fff;
}
.service-item {
	flex: 1 0 22%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	margin: .5rem;
	cursor: default;
}
.service-item img {
	width: 100%;
}
.service-item .container {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 2rem;
	font-size: 2rem;
	background-color: rgba(217, 21, 0, .5);
	color: #fff;
}
.services {
	min-height: 100vh;
	padding-top: 8rem;
	display: flex;
}
.services__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.services__container h1 {
	font-size: 4.5rem;
	text-align: center;
	color: var(--color-primary);
	border-bottom: 2px solid var(--color-primary);
}
.services__container ul {
	display: flex;
	list-style: none;
	flex-wrap: wrap;
	padding: 4.5rem 0;
}
.process {
	min-height: 100vh;
	padding-top: 8rem;
}
.process__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.process__container h1 {
	font-size: 4.5rem;
	text-align: center;
	color: var(--color-primary);
	border-bottom: 2px solid var(--color-primary);
}
.process__container ul {
	display: flex;
	flex-direction: column;
	list-style: none;
	width: 100%;
	max-width: 60rem;
	margin: 4.5rem auto;
}
.process__container ul li {
	display: flex;
	align-items: center;
}
.process__container ul li:nth-child(even) {
	flex-direction: row-reverse;
}
.process__container ul li picture {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
}
.process__container ul li picture img {
	width: 100%;
}
.process__container ul li .content {
	flex: 1;
	padding: 2rem;
	display: flex;
	flex-direction: column;
}
.process__container ul li .content h3 {
	font-size: 2.5rem;
	color: var(--color-primary);
}
.process__container ul li .content p {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-align: justify;
	margin-top: 1rem;
}
.process__container ul li .content a {
	padding: 1rem;
	background-color: var(--color-primary);
	color: #fff;
	text-decoration: none;
	margin-top: 1rem;
	margin-left: auto;
	font-size: 1.4rem;
	text-transform: uppercase;
}
.process-resalted {
	padding: 12.5rem 0;
	background-color: #fff;
}
.process-resalted__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}
.process-resalted__container h2 {
	font-size: 4.5rem;
	text-align: center;
	color: var(--color-primary);
}
.process-resalted__container a {
	font-size: 1.4rem;
	padding: 1.5rem;
	color: #fff;
	background-color: var(--color-primary);
	margin: 2.5rem auto;
	text-decoration: none;
	text-transform: uppercase;
}
.providers {
	min-height: 100vh;
	padding-top: 8rem;
}
.providers__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.providers__container h1 {
	font-size: 4.5rem;
	color: var(--color-primary);
}
.providers__container p {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	margin-top: 2rem;
}
.providers__container form {
	width: 100%;
	max-width: 60rem;
	margin: 4.5rem auto;
	padding: 2rem;
	background-color: #fff;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.registry__container {
	height: 100%;
	padding: 2.5rem;
	padding-top: 8rem;
	padding-right: 11.5rem;
	flex: 0 0 50%;
	background-color: #fff;
}
.registry__container h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	text-align: center;
}
.registry__container p {
	padding: 2.5rem;
	font-size: 1.4rem;
}
.registry__container h2 {
	font-size: 2rem;
	color: var(--color-primary);
}
.registry__container h3 {
	font-size: 1.8rem;
	color: var(--color-grey-dark-1);
	margin-top: 2rem;
}
.login .reg {
	margin-left: -9rem;
	position: sticky;
	background-color: rgba(255, 255, 255, .8);
}
.contact {
	padding: 2.5rem;
	padding-top: 8rem;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contact form {
	width: 100%;
	max-width: 60rem;
	padding: 2.5rem;
	background-color: rgba(255, 255, 255, .8);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.contact form h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	text-align: center;
	text-transform: uppercase;
}
.project {
	min-height: 100vh;
	display: flex;
	padding-top: 6rem;
}
.project__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	padding: 2.5rem;
	background-color: #fff;
}
.project__container h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	text-transform: uppercase;
}
.project__container ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;
}
.project__container ul li {
	display: flex;
	padding: 2rem;
	border-bottom: 1px solid var(--color-grey-light-3);
}
.project__container ul li span {
	font-size: 2rem;
	color: #fff;
	background-color: var(--color-primary);
	width: 4rem;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2.5rem;
	border-radius: 50%;
}
.project__container ul li .content {
	flex: 1;
}
.project__container ul li .content h3 {
	font-size: 2rem;
	color: var(--color-grey-dark-1);
}
.project__container ul li .content p {
	font-size: 1.4rem;
	margin-top: 1rem;
}
.terms {
	padding-top: 6rem;
}
.terms__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	padding: 2.5rem;
	background-color: #fff;
}
.terms__container h1 {
	font-size: 2.5rem;
	text-align: center;
	color: #111517;
}
.terms__container p {
	font-size: 1.4rem;
	margin-top: 1rem;
}
.terms__container h2 {
	font-size: 2rem;
	margin-top: 2rem;
	color: #111517;
}

/* ADMIN */
.admin {
	display: flex;
	min-height: 100vh;
}
.admin__sidenav {
	padding: 2rem;
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	flex: 1;
}
.admin__sidenav ul {
	display: flex;
	flex-direction: column;
}
.admin__sidenav ul a {
	padding: 2rem;
	font-size: 1.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	text-decoration: none;
	color: var(--color-grey-dark-1);
	transition: all .5s;
}
.admin__sidenav ul a:hover,
.admin__sidenav ul a.active {
	background-color: var(--color-primary);
	color: #fff;
}
.admin__container {
	flex: 4;
	padding: 2.5rem;
}
.admin-services form {
	display: flex;
	align-items: flex-end;
}
.admin-services form .input-container {
	flex: 1;
}
.admin-services ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style: none;
}
.admin-services ul li {
	flex: 1 0 23%;
	padding: 2rem;
	background-color: rgba(255, 255, 255, .6);
	margin: 1rem;
}
.admin-services ul li hr {
	border: none;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: transparent;
}
.admin table {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.4rem;
}
.admin table th {
	background-color: var(--color-primary);
	color: #fff;
	padding: 1rem;
}
.admin table td {
	font-size: 1.4rem;
	padding: 1rem;
	border-bottom: 1px solid var(--color-grey-dark-3);
	color: var(--color-grey-dark-1);
}
.admin table td a {
	color: var(--color-primary);
}
.admin-single-user-tracking {
	display: flex;
}
.admin-single-user-tracking form {
	flex: 1;
	background-color: rgba(255, 255, 255, .6);
	margin-right: 2.5rem;
	padding: 2rem;
}
.admin-single-user-tracking .content {
	flex: 3;
}
.admin-messages {
	display: flex;
}
.admin-messages table {
	flex: 1;
}

@media only screen and (max-width: 48em) {
	.header__container .logo {
		display: none;
	}
	.client header {
		height: unset;
		padding: 2rem 0;
	}
	.header__container {
		flex-wrap: wrap;
	}
	.header__container ul {
		height: unset;
		margin-bottom: 1rem;
	}
	.header__container ul a {
		flex: unset;
		margin-right: 2rem;
	}
	.header__container > a {
		flex: 1;
		text-align: center;
	}
	.home__cases-container ul {
		flex-direction: column;
	}
	.home__about-container {
		flex-direction: column-reverse;
	}
	.home__about-container .content {
		margin-right: 0;
		padding: 2rem;
	}
	.home__contact-container form {
		flex-direction: column;
		align-items: unset;
	}
	.home__contact-container form button {
		margin-top: 2rem;
	}
	.home__contact-container {
		padding: 0 2rem;
	}
	.process__container ul li {
		flex-direction: column;
	}
	.process__container ul li:nth-child(even) {
		flex-direction: column;
	}
	.services__container ul {
		flex-direction: column;
		flex-wrap: unset;
	}
	.service-item {
		flex: unset;
	}
	.providers {
		padding: 12rem 1rem;
	}
	.terms__container {
		padding: 4.5rem 1rem;
	}
	.registry__container {
		display: none;
	}
	.login .reg {
		margin-left: 0;
	}
	.login {
		padding: 0 1rem;
	}
}